/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateConfidentialsAdmin = /* GraphQL */ `
  subscription OnCreateConfidentialsAdmin(
    $filter: ModelSubscriptionConfidentialsAdminFilterInput
  ) {
    onCreateConfidentialsAdmin(filter: $filter) {
      id
      ConfidentialKey
      ConfidentialValue
      owner
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateConfidentialsAdmin = /* GraphQL */ `
  subscription OnUpdateConfidentialsAdmin(
    $filter: ModelSubscriptionConfidentialsAdminFilterInput
  ) {
    onUpdateConfidentialsAdmin(filter: $filter) {
      id
      ConfidentialKey
      ConfidentialValue
      owner
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteConfidentialsAdmin = /* GraphQL */ `
  subscription OnDeleteConfidentialsAdmin(
    $filter: ModelSubscriptionConfidentialsAdminFilterInput
  ) {
    onDeleteConfidentialsAdmin(filter: $filter) {
      id
      ConfidentialKey
      ConfidentialValue
      owner
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateCommentVote = /* GraphQL */ `
  subscription OnCreateCommentVote(
    $filter: ModelSubscriptionCommentVoteFilterInput
  ) {
    onCreateCommentVote(filter: $filter) {
      id
      user_id
      comment_id
      comment_user_id
      owner
      upvote
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateCommentVote = /* GraphQL */ `
  subscription OnUpdateCommentVote(
    $filter: ModelSubscriptionCommentVoteFilterInput
  ) {
    onUpdateCommentVote(filter: $filter) {
      id
      user_id
      comment_id
      comment_user_id
      owner
      upvote
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteCommentVote = /* GraphQL */ `
  subscription OnDeleteCommentVote(
    $filter: ModelSubscriptionCommentVoteFilterInput
  ) {
    onDeleteCommentVote(filter: $filter) {
      id
      user_id
      comment_id
      comment_user_id
      owner
      upvote
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateDealVote = /* GraphQL */ `
  subscription OnCreateDealVote($filter: ModelSubscriptionDealVoteFilterInput) {
    onCreateDealVote(filter: $filter) {
      id
      user_id
      deal_id
      deal_user_id
      owner
      upvote
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateDealVote = /* GraphQL */ `
  subscription OnUpdateDealVote($filter: ModelSubscriptionDealVoteFilterInput) {
    onUpdateDealVote(filter: $filter) {
      id
      user_id
      deal_id
      deal_user_id
      owner
      upvote
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteDealVote = /* GraphQL */ `
  subscription OnDeleteDealVote($filter: ModelSubscriptionDealVoteFilterInput) {
    onDeleteDealVote(filter: $filter) {
      id
      user_id
      deal_id
      deal_user_id
      owner
      upvote
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateVotedExpired = /* GraphQL */ `
  subscription OnCreateVotedExpired(
    $filter: ModelSubscriptionVotedExpiredFilterInput
    $owner: String
  ) {
    onCreateVotedExpired(filter: $filter, owner: $owner) {
      id
      user_id
      deal_id
      deal_user_id
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateVotedExpired = /* GraphQL */ `
  subscription OnUpdateVotedExpired(
    $filter: ModelSubscriptionVotedExpiredFilterInput
    $owner: String
  ) {
    onUpdateVotedExpired(filter: $filter, owner: $owner) {
      id
      user_id
      deal_id
      deal_user_id
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteVotedExpired = /* GraphQL */ `
  subscription OnDeleteVotedExpired(
    $filter: ModelSubscriptionVotedExpiredFilterInput
    $owner: String
  ) {
    onDeleteVotedExpired(filter: $filter, owner: $owner) {
      id
      user_id
      deal_id
      deal_user_id
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateReportedDeal = /* GraphQL */ `
  subscription OnCreateReportedDeal(
    $filter: ModelSubscriptionReportedDealFilterInput
    $owner: String
  ) {
    onCreateReportedDeal(filter: $filter, owner: $owner) {
      id
      user_id
      deal_id
      deal_user_id
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateReportedDeal = /* GraphQL */ `
  subscription OnUpdateReportedDeal(
    $filter: ModelSubscriptionReportedDealFilterInput
    $owner: String
  ) {
    onUpdateReportedDeal(filter: $filter, owner: $owner) {
      id
      user_id
      deal_id
      deal_user_id
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteReportedDeal = /* GraphQL */ `
  subscription OnDeleteReportedDeal(
    $filter: ModelSubscriptionReportedDealFilterInput
    $owner: String
  ) {
    onDeleteReportedDeal(filter: $filter, owner: $owner) {
      id
      user_id
      deal_id
      deal_user_id
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateComment = /* GraphQL */ `
  subscription OnCreateComment(
    $filter: ModelSubscriptionCommentFilterInput
    $owner: String
  ) {
    onCreateComment(filter: $filter, owner: $owner) {
      id
      deal_id
      user_name
      text
      reply_to
      likes
      dislikes
      poster_img_url
      createdAt
      updatedAt
      owner
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateComment = /* GraphQL */ `
  subscription OnUpdateComment(
    $filter: ModelSubscriptionCommentFilterInput
    $owner: String
  ) {
    onUpdateComment(filter: $filter, owner: $owner) {
      id
      deal_id
      user_name
      text
      reply_to
      likes
      dislikes
      poster_img_url
      createdAt
      updatedAt
      owner
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteComment = /* GraphQL */ `
  subscription OnDeleteComment(
    $filter: ModelSubscriptionCommentFilterInput
    $owner: String
  ) {
    onDeleteComment(filter: $filter, owner: $owner) {
      id
      deal_id
      user_name
      text
      reply_to
      likes
      dislikes
      poster_img_url
      createdAt
      updatedAt
      owner
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateExpoPushToken = /* GraphQL */ `
  subscription OnCreateExpoPushToken(
    $filter: ModelSubscriptionExpoPushTokenFilterInput
    $owner: String
  ) {
    onCreateExpoPushToken(filter: $filter, owner: $owner) {
      id
      userId
      username
      expoToken
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateExpoPushToken = /* GraphQL */ `
  subscription OnUpdateExpoPushToken(
    $filter: ModelSubscriptionExpoPushTokenFilterInput
    $owner: String
  ) {
    onUpdateExpoPushToken(filter: $filter, owner: $owner) {
      id
      userId
      username
      expoToken
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteExpoPushToken = /* GraphQL */ `
  subscription OnDeleteExpoPushToken(
    $filter: ModelSubscriptionExpoPushTokenFilterInput
    $owner: String
  ) {
    onDeleteExpoPushToken(filter: $filter, owner: $owner) {
      id
      userId
      username
      expoToken
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateDeal = /* GraphQL */ `
  subscription OnCreateDeal(
    $filter: ModelSubscriptionDealFilterInput
    $owner: String
  ) {
    onCreateDeal(filter: $filter, owner: $owner) {
      id
      store_sku
      title
      title_lowercase
      description
      description_lowercase
      price_drop
      deal_link
      affiliate_link
      img_link
      has_promotional_code
      promotional_code
      has_amazon_subscribe_save
      amazon_subscribe_save
      has_coupon
      coupon
      expiration_date
      poster_id
      poster_name
      forum_type
      forum_type_lowercase
      sub_category
      sub_category_lowercase
      dealer_type
      dealer_type_lowercase
      instore
      dealType
      dealDayType
      isTopDeal
      isTrendingDeal
      isLightningDeal
      specific_states
      available_states
      specific_stores
      available_store_addresses
      available_store_zipcodes
      available_store_geohashes
      available_store_placeID
      vote
      price
      expired
      expired_status_string
      expired_ttl
      poster_img_url
      prev_price
      uploaded_img_links
      free_shipping
      free_pickup
      down_vote
      posted_date
      updated_date
      ExpiredVotedNumber
      ExpiredVotedNumberAccumulated
      ReportedNumber
      ReportedNumberAccumulated
      highest_votes
      highest_ratio
      createdAt
      updatedAt
      owner
      search_by_time
      search_by_vote
      additionalTitles
      additionalLinks
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateDeal = /* GraphQL */ `
  subscription OnUpdateDeal(
    $filter: ModelSubscriptionDealFilterInput
    $owner: String
  ) {
    onUpdateDeal(filter: $filter, owner: $owner) {
      id
      store_sku
      title
      title_lowercase
      description
      description_lowercase
      price_drop
      deal_link
      affiliate_link
      img_link
      has_promotional_code
      promotional_code
      has_amazon_subscribe_save
      amazon_subscribe_save
      has_coupon
      coupon
      expiration_date
      poster_id
      poster_name
      forum_type
      forum_type_lowercase
      sub_category
      sub_category_lowercase
      dealer_type
      dealer_type_lowercase
      instore
      dealType
      dealDayType
      isTopDeal
      isTrendingDeal
      isLightningDeal
      specific_states
      available_states
      specific_stores
      available_store_addresses
      available_store_zipcodes
      available_store_geohashes
      available_store_placeID
      vote
      price
      expired
      expired_status_string
      expired_ttl
      poster_img_url
      prev_price
      uploaded_img_links
      free_shipping
      free_pickup
      down_vote
      posted_date
      updated_date
      ExpiredVotedNumber
      ExpiredVotedNumberAccumulated
      ReportedNumber
      ReportedNumberAccumulated
      highest_votes
      highest_ratio
      createdAt
      updatedAt
      owner
      search_by_time
      search_by_vote
      additionalTitles
      additionalLinks
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteDeal = /* GraphQL */ `
  subscription OnDeleteDeal(
    $filter: ModelSubscriptionDealFilterInput
    $owner: String
  ) {
    onDeleteDeal(filter: $filter, owner: $owner) {
      id
      store_sku
      title
      title_lowercase
      description
      description_lowercase
      price_drop
      deal_link
      affiliate_link
      img_link
      has_promotional_code
      promotional_code
      has_amazon_subscribe_save
      amazon_subscribe_save
      has_coupon
      coupon
      expiration_date
      poster_id
      poster_name
      forum_type
      forum_type_lowercase
      sub_category
      sub_category_lowercase
      dealer_type
      dealer_type_lowercase
      instore
      dealType
      dealDayType
      isTopDeal
      isTrendingDeal
      isLightningDeal
      specific_states
      available_states
      specific_stores
      available_store_addresses
      available_store_zipcodes
      available_store_geohashes
      available_store_placeID
      vote
      price
      expired
      expired_status_string
      expired_ttl
      poster_img_url
      prev_price
      uploaded_img_links
      free_shipping
      free_pickup
      down_vote
      posted_date
      updated_date
      ExpiredVotedNumber
      ExpiredVotedNumberAccumulated
      ReportedNumber
      ReportedNumberAccumulated
      highest_votes
      highest_ratio
      createdAt
      updatedAt
      owner
      search_by_time
      search_by_vote
      additionalTitles
      additionalLinks
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateInStoreDealByState = /* GraphQL */ `
  subscription OnCreateInStoreDealByState(
    $filter: ModelSubscriptionInStoreDealByStateFilterInput
    $owner: String
  ) {
    onCreateInStoreDealByState(filter: $filter, owner: $owner) {
      id
      deal_id
      store_name
      available_state
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateInStoreDealByState = /* GraphQL */ `
  subscription OnUpdateInStoreDealByState(
    $filter: ModelSubscriptionInStoreDealByStateFilterInput
    $owner: String
  ) {
    onUpdateInStoreDealByState(filter: $filter, owner: $owner) {
      id
      deal_id
      store_name
      available_state
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteInStoreDealByState = /* GraphQL */ `
  subscription OnDeleteInStoreDealByState(
    $filter: ModelSubscriptionInStoreDealByStateFilterInput
    $owner: String
  ) {
    onDeleteInStoreDealByState(filter: $filter, owner: $owner) {
      id
      deal_id
      store_name
      available_state
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateInStoreDealGeoHash = /* GraphQL */ `
  subscription OnCreateInStoreDealGeoHash(
    $filter: ModelSubscriptionInStoreDealGeoHashFilterInput
    $owner: String
  ) {
    onCreateInStoreDealGeoHash(filter: $filter, owner: $owner) {
      id
      deal_id
      geohash
      address
      store_name
      zipcode
      googlemap_placeid
      geohash3
      geohash4
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateInStoreDealGeoHash = /* GraphQL */ `
  subscription OnUpdateInStoreDealGeoHash(
    $filter: ModelSubscriptionInStoreDealGeoHashFilterInput
    $owner: String
  ) {
    onUpdateInStoreDealGeoHash(filter: $filter, owner: $owner) {
      id
      deal_id
      geohash
      address
      store_name
      zipcode
      googlemap_placeid
      geohash3
      geohash4
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteInStoreDealGeoHash = /* GraphQL */ `
  subscription OnDeleteInStoreDealGeoHash(
    $filter: ModelSubscriptionInStoreDealGeoHashFilterInput
    $owner: String
  ) {
    onDeleteInStoreDealGeoHash(filter: $filter, owner: $owner) {
      id
      deal_id
      geohash
      address
      store_name
      zipcode
      googlemap_placeid
      geohash3
      geohash4
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAlertSettings = /* GraphQL */ `
  subscription OnCreateAlertSettings(
    $filter: ModelSubscriptionAlertSettingsFilterInput
    $owner: String
  ) {
    onCreateAlertSettings(filter: $filter, owner: $owner) {
      id
      alert_title
      user_id
      username
      enabled
      keywords
      stores
      categories
      sub_categories
      price_min
      price_max
      threshold_net_upvote
      threshold_upvote_ratio
      notification_email
      notification_cell
      notification_app
      frequency_instant
      frequency_daily
      frequency_weekly
      last_changed_date
      hash_key
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAlertSettings = /* GraphQL */ `
  subscription OnUpdateAlertSettings(
    $filter: ModelSubscriptionAlertSettingsFilterInput
    $owner: String
  ) {
    onUpdateAlertSettings(filter: $filter, owner: $owner) {
      id
      alert_title
      user_id
      username
      enabled
      keywords
      stores
      categories
      sub_categories
      price_min
      price_max
      threshold_net_upvote
      threshold_upvote_ratio
      notification_email
      notification_cell
      notification_app
      frequency_instant
      frequency_daily
      frequency_weekly
      last_changed_date
      hash_key
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAlertSettings = /* GraphQL */ `
  subscription OnDeleteAlertSettings(
    $filter: ModelSubscriptionAlertSettingsFilterInput
    $owner: String
  ) {
    onDeleteAlertSettings(filter: $filter, owner: $owner) {
      id
      alert_title
      user_id
      username
      enabled
      keywords
      stores
      categories
      sub_categories
      price_min
      price_max
      threshold_net_upvote
      threshold_upvote_ratio
      notification_email
      notification_cell
      notification_app
      frequency_instant
      frequency_daily
      frequency_weekly
      last_changed_date
      hash_key
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateTopDealAlertSettings = /* GraphQL */ `
  subscription OnCreateTopDealAlertSettings(
    $filter: ModelSubscriptionTopDealAlertSettingsFilterInput
    $owner: String
  ) {
    onCreateTopDealAlertSettings(filter: $filter, owner: $owner) {
      id
      user_id
      username
      enabled
      notification_email
      notification_cell
      notification_app
      frequency_instant
      frequency_daily
      frequency_weekly
      hash_key
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateTopDealAlertSettings = /* GraphQL */ `
  subscription OnUpdateTopDealAlertSettings(
    $filter: ModelSubscriptionTopDealAlertSettingsFilterInput
    $owner: String
  ) {
    onUpdateTopDealAlertSettings(filter: $filter, owner: $owner) {
      id
      user_id
      username
      enabled
      notification_email
      notification_cell
      notification_app
      frequency_instant
      frequency_daily
      frequency_weekly
      hash_key
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteTopDealAlertSettings = /* GraphQL */ `
  subscription OnDeleteTopDealAlertSettings(
    $filter: ModelSubscriptionTopDealAlertSettingsFilterInput
    $owner: String
  ) {
    onDeleteTopDealAlertSettings(filter: $filter, owner: $owner) {
      id
      user_id
      username
      enabled
      notification_email
      notification_cell
      notification_app
      frequency_instant
      frequency_daily
      frequency_weekly
      hash_key
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateLightningDealAlertSettings = /* GraphQL */ `
  subscription OnCreateLightningDealAlertSettings(
    $filter: ModelSubscriptionLightningDealAlertSettingsFilterInput
    $owner: String
  ) {
    onCreateLightningDealAlertSettings(filter: $filter, owner: $owner) {
      id
      user_id
      username
      enabled
      notification_email
      notification_cell
      notification_app
      frequency_instant
      frequency_daily
      frequency_weekly
      hash_key
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateLightningDealAlertSettings = /* GraphQL */ `
  subscription OnUpdateLightningDealAlertSettings(
    $filter: ModelSubscriptionLightningDealAlertSettingsFilterInput
    $owner: String
  ) {
    onUpdateLightningDealAlertSettings(filter: $filter, owner: $owner) {
      id
      user_id
      username
      enabled
      notification_email
      notification_cell
      notification_app
      frequency_instant
      frequency_daily
      frequency_weekly
      hash_key
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteLightningDealAlertSettings = /* GraphQL */ `
  subscription OnDeleteLightningDealAlertSettings(
    $filter: ModelSubscriptionLightningDealAlertSettingsFilterInput
    $owner: String
  ) {
    onDeleteLightningDealAlertSettings(filter: $filter, owner: $owner) {
      id
      user_id
      username
      enabled
      notification_email
      notification_cell
      notification_app
      frequency_instant
      frequency_daily
      frequency_weekly
      hash_key
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateBookmark = /* GraphQL */ `
  subscription OnCreateBookmark(
    $filter: ModelSubscriptionBookmarkFilterInput
    $owner: String
  ) {
    onCreateBookmark(filter: $filter, owner: $owner) {
      id
      user_id
      deal_id
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateBookmark = /* GraphQL */ `
  subscription OnUpdateBookmark(
    $filter: ModelSubscriptionBookmarkFilterInput
    $owner: String
  ) {
    onUpdateBookmark(filter: $filter, owner: $owner) {
      id
      user_id
      deal_id
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteBookmark = /* GraphQL */ `
  subscription OnDeleteBookmark(
    $filter: ModelSubscriptionBookmarkFilterInput
    $owner: String
  ) {
    onDeleteBookmark(filter: $filter, owner: $owner) {
      id
      user_id
      deal_id
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreatePointToPointMessages = /* GraphQL */ `
  subscription OnCreatePointToPointMessages(
    $filter: ModelSubscriptionPointToPointMessagesFilterInput
    $sender: String
    $receiver: String
  ) {
    onCreatePointToPointMessages(
      filter: $filter
      sender: $sender
      receiver: $receiver
    ) {
      id
      sender
      receiver
      createdAt
      _ttl
      type
      title
      body
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdatePointToPointMessages = /* GraphQL */ `
  subscription OnUpdatePointToPointMessages(
    $filter: ModelSubscriptionPointToPointMessagesFilterInput
    $sender: String
    $receiver: String
  ) {
    onUpdatePointToPointMessages(
      filter: $filter
      sender: $sender
      receiver: $receiver
    ) {
      id
      sender
      receiver
      createdAt
      _ttl
      type
      title
      body
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeletePointToPointMessages = /* GraphQL */ `
  subscription OnDeletePointToPointMessages(
    $filter: ModelSubscriptionPointToPointMessagesFilterInput
    $sender: String
    $receiver: String
  ) {
    onDeletePointToPointMessages(
      filter: $filter
      sender: $sender
      receiver: $receiver
    ) {
      id
      sender
      receiver
      createdAt
      _ttl
      type
      title
      body
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateGlobalMessages = /* GraphQL */ `
  subscription OnCreateGlobalMessages(
    $filter: ModelSubscriptionGlobalMessagesFilterInput
    $sender: String
  ) {
    onCreateGlobalMessages(filter: $filter, sender: $sender) {
      id
      sender
      createdAt
      _ttl
      type
      title
      body
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateGlobalMessages = /* GraphQL */ `
  subscription OnUpdateGlobalMessages(
    $filter: ModelSubscriptionGlobalMessagesFilterInput
    $sender: String
  ) {
    onUpdateGlobalMessages(filter: $filter, sender: $sender) {
      id
      sender
      createdAt
      _ttl
      type
      title
      body
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteGlobalMessages = /* GraphQL */ `
  subscription OnDeleteGlobalMessages(
    $filter: ModelSubscriptionGlobalMessagesFilterInput
    $sender: String
  ) {
    onDeleteGlobalMessages(filter: $filter, sender: $sender) {
      id
      sender
      createdAt
      _ttl
      type
      title
      body
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreatePreferredUsernameMapping = /* GraphQL */ `
  subscription OnCreatePreferredUsernameMapping(
    $filter: ModelSubscriptionPreferredUsernameMappingFilterInput
    $owner: String
  ) {
    onCreatePreferredUsernameMapping(filter: $filter, owner: $owner) {
      preferred_username
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdatePreferredUsernameMapping = /* GraphQL */ `
  subscription OnUpdatePreferredUsernameMapping(
    $filter: ModelSubscriptionPreferredUsernameMappingFilterInput
    $owner: String
  ) {
    onUpdatePreferredUsernameMapping(filter: $filter, owner: $owner) {
      preferred_username
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeletePreferredUsernameMapping = /* GraphQL */ `
  subscription OnDeletePreferredUsernameMapping(
    $filter: ModelSubscriptionPreferredUsernameMappingFilterInput
    $owner: String
  ) {
    onDeletePreferredUsernameMapping(filter: $filter, owner: $owner) {
      preferred_username
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateStore = /* GraphQL */ `
  subscription OnCreateStore(
    $filter: ModelSubscriptionStoreFilterInput
    $owner: String
  ) {
    onCreateStore(filter: $filter, owner: $owner) {
      id
      storeName
      storeNameLowerCase
      count
      recentUpdatesDateTime
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateStore = /* GraphQL */ `
  subscription OnUpdateStore(
    $filter: ModelSubscriptionStoreFilterInput
    $owner: String
  ) {
    onUpdateStore(filter: $filter, owner: $owner) {
      id
      storeName
      storeNameLowerCase
      count
      recentUpdatesDateTime
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteStore = /* GraphQL */ `
  subscription OnDeleteStore(
    $filter: ModelSubscriptionStoreFilterInput
    $owner: String
  ) {
    onDeleteStore(filter: $filter, owner: $owner) {
      id
      storeName
      storeNameLowerCase
      count
      recentUpdatesDateTime
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
