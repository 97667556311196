export const user_white_list= {
    "eb373c88-50d8-4055-b0e4-ceb5ed98fc35": "dealHAWK",
    "5cc7fc34-dec8-4b04-8915-5a57c349c2da": "LordOfSavings",
    "3c3d4adf-0029-4586-9f42-6e27a2f7d3b3": "deal_ninja",
    "3bdf56d2-bc5c-4ba2-951b-429b02d81011": "pika",
    "5ee53136-6632-4437-92b3-bd6a895c4139": "frank",
    "0128b755-4ce2-4fec-a076-3ed285dfb0d1": "lordofdiscounts",
    "fd27abcb-0c8a-406e-873f-94193e3af962": "google_113128107602760576361",
    "fdb5c12e-a88d-42b5-ad69-139e3b162d31": "gqx.2",
    "a71ff1fd-b93a-4c4f-b650-03fdd9188410": "kwhetstine",
    "1a2eafbe-efdd-4c79-9da5-0782b3182cd9": "google_116993133207291928791",
    "f7801c92-d88d-444a-ab2d-f521124eff07": "cattatlex",
    "e304ef90-2b79-4f54-bc37-dc52a8c9c899": "commonknowledge",
};