import GridOnIcon from '@mui/icons-material/GridOn';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import React from 'react';
// import CustomPagination from '../utils/CustomPagination';
// import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import { BsFillPinMapFill } from 'react-icons/bs';
// import { LuRectangleVertical } from "react-icons/lu";
import SectionInstorePage from '../home/SectionInstorePage';
import CustomPaginationEndlessMode from '../utils/CustomPaginationEndlessMode';
import Map_v2_API_ByState from './Map_v2_API_ByState';
// import CustomPaginationEndlessMode from '../utils/CustomPaginationEndlessMode';
import Post from '../utils/Post';
import MapMostFilter from './MapMostFilter';
import MapMostFilterPills from './MapMostFilterPills';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { emptyDemoDataList } from '../../api/deal';
import { useAppContext_ } from '../../AppContextProvider';
import usePropState from '../../utils/usePropState';
import { user_white_list } from "../constants/WhiteListUsers";

const MAP_ID = '982c1d319487e8e4';

function Map_v2() {
  const {
    dealsInMostLocation, //related to nextTokenRef
    nextTokenRef,
    getDealsByState, // update this method
    mostLoading,
    setMostLoading,
    sortDeals,
    filter,setFilter,
    selectedCount,
    selectedFiltersCount
  } = Map_v2_API_ByState({});
  //const [view, setView] = useState('grid');
  //const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState(false);
  const [sortBy, setSortBy] = usePropState(filter.sort_by?filter.sort_by:'Most Recent');
  const [view, setView] = useState('grid');
  const navigate = useNavigate();
  const { user } = useAppContext_();

  useEffect(() => {
      if(user){
        if(user.attributes.sub in user_white_list){
          window.scrollTo(0, 0);
        }
        else{
          navigate('/backup');
        }
      }
      else{
        navigate('/backup');
      }
  }, []);

  const handleView = (event, value) => {
    setView(value);
  };

  const showPosts = (items) =>
    view === 'grid' ? (
      <div
        className="tw-grid tw-gap-1 sm:tw-gap-2 md:tw-gap-3 xl:tw-gap-5
         tw-grid-cols-2 sm:tw-grid-cols-3 md:tw-grid-cols-3 lg:tw-grid-cols-3 xl:tw-grid-cols-4 2xl:tw-grid-cols-5"
      >
        {items.map((item, index) => (
          <Post item={item} orientation="vertical" type="Lightning Deal" />
        ))}
      </div>
    ) : (
      <Grid
        spacing={{ xs: 0.5, sm: 1, md: 2, lg: 3 }}
        container
        direction="column"
        justifyContent="center"
        alignItems="stretch"
      >
        {items.map((item, index) => (
          <Grid key={index} item xs={12} sm={12} md={12} lg={12}>
            <Post item={item} orientation="horizontal" type="Lightning Deal" />
          </Grid>
        ))}
      </Grid>
    );

  const handleSortBy = (event) => {
    setSortBy(event.target.value);
    // sortDeals(event.target.value);
    const filterCopy = structuredClone(filter);
    filterCopy.sort_by = event.target.value;
    setFilter(filterCopy);
  };

  //if (loadError) return 'Error loading maps';
  // if (!isLoaded) return <Box sx={{ height: 1000, backgroundColor: 'white' }} />;
  if(mostLoading){
    return (
      <div>
        <SectionInstorePage
          startIcon={
            <BsFillPinMapFill style={{ color: 'rgba(13, 110, 253, 1)' }} />
          }
          title="Find In-Store Deal"
          content={
            <div className="tw-flex tw-flex-col tw-gap-y-4 lg:tw-gap-y-8 tw-w-full">
              <Box fullWidth className="tw-py-1">
                <div className="tw-rounded-sm tw-flex tw-gap-x-1 tw-p-1">
                  <img
                    className="tw-w-full"
                    src="/instore-banner-v2.png"
                    alt="First banner"
                  />
                </div>
              </Box>
              <div className="tw-flex tw-items-center tw-gap-x-4 tw-w-full">
                <div className="tw-py-3 tw-w-full lg:tw-w-3/6 xl:tw-w-[300px]">
                  <Button
                    fullWidth
                    variant="outlined"
                    startIcon={
                      <TuneRoundedIcon
                        sx={{
                          color: selectedCount > 0 ? '#3054F5' : 'black',
                        }}
                      />
                    }
                    size="large"
                    color="black"
                    sx={{
                      height: { xs: 'undefined', md: '3.75rem' },
                    }}
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <div
                      className={`tw-whitespace-nowrap ${
                        selectedCount > 0
                          ? 'tw-text-string-primary tw-font-semibold'
                          : 'tw-text-string-base'
                      }`}
                    >{`Filters (${selectedCount})`}</div>
                  </Button>
                </div>
                <Box
                  sx={{ display: { xs: 'none', md: 'block' }, width: '100%' }}
                >
                  <div className="tw-bg-surface-raised tw-flex tw-flex-1 tw-items-center tw-p-3 tw-px-8 tw-rounded-lg tw-border tw-border-surface-border tw-text-string-base">
                    <p className="tw-w-[55%] tw-flex tw-items-center tw-m-0">
                      0 deals
                    </p>

                    <div className="tw-flex-1 tw-flex tw-gap-x-3 tw-justify-between tw-items-center">
                      <div className="tw-flex-1">
                        <FormControl fullWidth size="small">
                          <Select value={sortBy} onChange={handleSortBy}>
                            <MenuItem value={'Most Recent'}>
                              Most Recent
                            </MenuItem>
                            <MenuItem value={'Top Deals'}>Top Deals</MenuItem>
                            <MenuItem value={'Percentage Off'}>
                              Percentage Off
                            </MenuItem>
                            <MenuItem value={'Trending Deals'}>
                              Trending Deals
                            </MenuItem>
                            {/*
                                    <MenuItem value={'Alphabetically'}>
                                    Alphabetically
                                    </MenuItem>
                                    */}
                            <MenuItem value={'Promo code included'}>
                              Promo code included
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <ToggleButtonGroup
                        size="small"
                        value={view}
                        exclusive
                        aria-label="view"
                        onChange={handleView}
                      >
                        <ToggleButton value="grid" aria-label="left aligned">
                          <GridOnIcon />
                        </ToggleButton>
                        <ToggleButton
                          value="horizontal"
                          aria-label="left aligned"
                        >
                          <ViewHeadlineIcon />
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </div>
                </Box>
                {filter.sort_by &&
                  <Box
                    sx={{ display: { xs: 'block', md: 'none' }, width: '100%' }}
                   >
                    <div className="tw-flex-1">
                      <FormControl fullWidth size="small">
                        <Select value={sortBy} onChange={handleSortBy}>
                          <MenuItem value={'Most Recent'}>Most Recent</MenuItem>
                          <MenuItem value={'Top Deals'}>Top Deals</MenuItem>
                          <MenuItem value={'Percentage Off'}>
                            Percentage Off
                          </MenuItem>
                          <MenuItem value={'Trending Deals'}>
                            Trending Deals
                          </MenuItem>
                          {/*
                                  <MenuItem value={'Alphabetically'}>
                                  Alphabetically
                                  </MenuItem>
                                  */}
                          <MenuItem value={'Promo code included'}>
                            Promo code included
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Box>
                }
              </div>
              <MapMostFilterPills
                filter={filter}
                setFilter={setFilter}
                count={selectedFiltersCount(filter)}
              />
              <CustomPaginationEndlessMode
                  items={emptyDemoDataList}
                  component={(items) => showPosts(items)}
                  fetchNextPage={getDealsByState} // update this method
                  hasMore={nextTokenRef.current === null ? false : true}
              />
            </div>
          }
        ></SectionInstorePage>
              <MapMostFilter
                open={showFilter}
                filter={filter}
                onClose={() => setShowFilter(!showFilter)}
                setFilter={setFilter}
                selectedFiltersCount={selectedFiltersCount}
          />
      </div>
    );
  }
  return (
    <div>
      <SectionInstorePage
        startIcon={
          <BsFillPinMapFill style={{ color: 'rgba(13, 110, 253, 1)' }} />
        }
        title="Find In-Store Deal"
        content={
          <div className="tw-flex tw-flex-col tw-gap-y-4 lg:tw-gap-y-4 tw-w-full">
              <Box fullWidth className="tw-py-1">
                <div className="tw-rounded-sm tw-flex tw-gap-x-1 tw-p-1">
                  <img
                    className="tw-w-full"
                    src="/instore-banner-v2.png"
                    alt="First banner"
                  />
                </div>
              </Box>
              <div className="tw-flex tw-items-center tw-gap-x-4 tw-w-full">
                <div className="tw-py-3 tw-w-full lg:tw-w-3/6 xl:tw-w-[300px]">
                  <Button
                    fullWidth
                    variant="outlined"
                    startIcon={
                      <TuneRoundedIcon
                        sx={{
                          color: selectedCount > 0 ? '#398fed' : 'black',
                        }}
                      />
                    }
                    size="large"
                    color="black"
                    sx={{
                      height: { xs: 'undefined', md: '3.75rem' },
                    }}
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <div
                      className={`tw-whitespace-nowrap ${
                        selectedCount > 0
                          ? 'tw-text-string-primary tw-font-semibold'
                          : 'tw-text-string-base'
                      }`}
                    >{`Filters (${selectedCount})`}</div>
                  </Button>
                </div>
                <Box
                    sx={{ display: { xs: 'none', md: 'block' }, width: '100%' }}
                  >
                  <div className="tw-bg-surface-raised tw-flex tw-flex-1 tw-items-center tw-p-3 tw-px-8 tw-rounded-lg tw-border tw-border-surface-border tw-text-string-base">
                    <p className="tw-w-[55%] tw-flex tw-items-center tw-m-0">
                      {dealsInMostLocation ? dealsInMostLocation.length : 0} deals
                    </p>

                    <div className="tw-flex-1 tw-flex tw-gap-x-3 tw-justify-between tw-items-center">
                      <div className="tw-flex-1">
                        <FormControl fullWidth size="small">
                          <Select value={sortBy} onChange={handleSortBy}>
                            <MenuItem value={'Most Recent'}>
                              Most Recent
                            </MenuItem>
                            <MenuItem value={'Top Deals'}>Top Deals</MenuItem>
                            <MenuItem value={'Percentage Off'}>
                              Percentage Off
                            </MenuItem>
                            <MenuItem value={'Trending Deals'}>
                              Trending Deals
                            </MenuItem>
                            {/*
                            <MenuItem value={'Alphabetically'}>
                              Alphabetically
                            </MenuItem>
                            */}
                            <MenuItem value={'Promo code included'}>
                              Promo code included
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <ToggleButtonGroup
                        size="small"
                        value={view}
                        exclusive
                        aria-label="view"
                        onChange={handleView}
                      >
                        <ToggleButton value="grid" aria-label="left aligned">
                          <GridOnIcon />
                        </ToggleButton>
                        <ToggleButton
                          value="horizontal"
                          aria-label="left aligned"
                        >
                          <ViewHeadlineIcon />
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </div>
                </Box>
                {filter.sort_by &&
                  <Box
                    sx={{ display: { xs: 'block', md: 'none' }, width: '100%' }}
                  >
                  <div className="tw-flex-1">
                    <FormControl fullWidth size="small">
                      <Select value={sortBy} onChange={handleSortBy}>
                        <MenuItem value={'Most Recent'}>Most Recent</MenuItem>
                        <MenuItem value={'Top Deals'}>Top Deals</MenuItem>
                        <MenuItem value={'Percentage Off'}>
                          Percentage Off
                        </MenuItem>
                        <MenuItem value={'Trending Deals'}>
                          Trending Deals
                        </MenuItem>
                        {/*
                        <MenuItem value={'Alphabetically'}>
                          Alphabetically
                        </MenuItem>
                        */}
                        <MenuItem value={'Promo code included'}>
                          Promo code included
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </Box>
                }
              </div>
              <MapMostFilterPills
                filter={filter}
                setFilter={setFilter}
                count={selectedFiltersCount(filter)}
              />
              <CustomPaginationEndlessMode
                items={dealsInMostLocation}
                component={(items) => showPosts(items)}
                fetchNextPage={getDealsByState} // update this method
                hasMore={nextTokenRef.current === null ? false : true}
              />

          </div>
        }
      ></SectionInstorePage>
           <MapMostFilter
                open={showFilter}
                filter={filter}
                onClose={() => setShowFilter(!showFilter)}
                setFilter={setFilter}
                selectedFiltersCount={selectedFiltersCount}/>
    </div>
  );
}

export default Map_v2;
