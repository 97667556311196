import * as React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/header/Header';

import { ThemeProvider } from '@mui/material';
import Home from './components/home/Home';

import { AppContextProvider, useAppContext_ } from './AppContextProvider';

// import OnWebNotification from './components/alert/OnWebNotification';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import ResetPassword from './components/auth/ResetPassword';
import Verify from './components/auth/Verify';

import Bookmarks from './components/bookmarks/Bookmarks';
import BrandsList from './components/brands/BrandsList';
import DealEditorIndex from './components/deal/DealEditorIndex';
import Comments from './components/dealDetails/Comments';
import DealDetails_ from './components/dealDetails/DealDetails_';

import Footer from './components/footer/Footer';
import DealThreads from './components/forum/DealThreads';
import CookiesPolicy from './components/policies/CookiesPolicy';
import Disclaimer from './components/policies/Disclaimer';
import PrivacyPolicy from './components/policies/PrivacyPolicy';
import TermsConditions from './components/policies/TermsConditions';
import Profile from './components/profile/Profile';
import PublicProfile from './components/profile/PublicProfile';

import { Container } from '@mui/material';
import BottomNavigation from '../src/BottomNavigation';
import AdminPage from './components/admin/AdminPage';
import MySharedDealListPage from './components/deal/MySharedDealListPage';
import Filter_v2 from './components/filter/Filter_v2';
import Backup from './components/forum/Backup';
import Contacts from './components/forum/Contacts';
import ProfileSidebar from './components/sidebar/ProfileSidebar';
import ApiTestPage from './components/test/ApiTestPage';

// import Nearby from './components/map/NearbyInstoreDealsMap';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useState } from 'react';
import FederatedUsernameCheck from './components/FederatedUsernameCheck';
import Alerts from './components/alerts/Alerts';
import SetUsername from './components/auth/SetUsername';
import BigDeals from './components/bigDealDay/BigDeals';
import Deals from './components/deals/Deals';
import DealsLightning from './components/dealsLightning/DealsLightning';
import Map_v2 from './components/map/Map_v2';
import { NotificationListener } from './components/notification/NotificationListener';
import { dark, light } from './theme';

const queryClient = new QueryClient();

// Parent component for rendering child routes
function AppLayout() {
  const { theme } = useAppContext_();

  return (
    <ThemeProvider theme={theme === 'light' ? light : dark}>
      <div className="tw-bg-surface-base tw-flex tw-flex-col tw-h-full">
        <Header />
        <div className="tw-flex-1">
          <Outlet />
        </div>
        <Footer />
        <BottomNavigation />
      </div>
    </ThemeProvider>
  );
}

function AppRoutes() {
  const { user } = useAppContext_();
  const [sidebarWidth, setSidebarWidth] = useState(null);
  const user_white_list= {
    "eb373c88-50d8-4055-b0e4-ceb5ed98fc35": "dealHAWK",
    "5cc7fc34-dec8-4b04-8915-5a57c349c2da": "LordOfSavings",
    "3c3d4adf-0029-4586-9f42-6e27a2f7d3b3": "deal_ninja",
    "3bdf56d2-bc5c-4ba2-951b-429b02d81011": "pika",
    "5ee53136-6632-4437-92b3-bd6a895c4139": "frank",
    "0128b755-4ce2-4fec-a076-3ed285dfb0d1": "lordofdiscounts",
    "fd27abcb-0c8a-406e-873f-94193e3af962": "google_113128107602760576361",
    "fdb5c12e-a88d-42b5-ad69-139e3b162d31": "gqx.2",
    "a71ff1fd-b93a-4c4f-b650-03fdd9188410": "kwhetstine",
    "1a2eafbe-efdd-4c79-9da5-0782b3182cd9": "google_116993133207291928791",
    "f7801c92-d88d-444a-ab2d-f521124eff07": "cattatlex",
    "e304ef90-2b79-4f54-bc37-dc52a8c9c899": "commonknowledge",
};

  return (
    <Routes>
      {user && user.attributes.sub in user_white_list? (
        <Route path="/" element={<AppLayout />}>
          <Route element={<FederatedUsernameCheck />}>
            <Route path="/" element={<Home />} />
            {/* Authentication flow. */}
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/set-username" element={<SetUsername />} />
            <Route path="/verify" element={<Verify />} />
            <Route path="/reset-password" element={<ResetPassword />} />

            <Route
              path="/profile"
              element={
                <Container
                  sx={{
                    marginTop: '15px',
                    marginLeft: sidebarWidth,
                    width: `calc(100% - ${sidebarWidth})`,
                  }}
                >
                  <ProfileSidebar sidebarIndex={0} />
                  <Profile />
                </Container>
              }
            />
            <Route path="/comments" element={<Comments />} />
            {/*
              <Route element={<PrivateRoute />}>
                <Route path="/comments" element={<Comments />} />
              </Route>
              */}
            <Route
              path="/alerts"
              element={
                <Container
                  sx={{
                    marginTop: '15px',
                    marginLeft: sidebarWidth,
                    width: `calc(100% - ${sidebarWidth})`,
                  }}
                >
                  <ProfileSidebar sidebarIndex={1} />
                  <Alerts />
                </Container>
              }
            />
            <Route path="/AlertList" element={<Alerts />} />
            <Route path="/alerts" element={<Alerts />} />
            <Route path="/bookmarks" element={<Bookmarks />} />
            <Route path="/brands" element={<BrandsList />} />
            <Route path="/map" element={<Map_v2 />} />
            {/* <Route path="/categoryForum" element={<ElecForum />} /> */}
            {/* <Route path="/DealerForum" element={<DealerForum />} /> */}
            <Route path="/threads" element={<DealThreads />} />
            <Route path="/share-deal" element={<DealEditorIndex />} />
            <Route path="/cookies-policy" element={<CookiesPolicy />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsConditions />} />
            <Route path="/deal-details/:id" element={<DealDetails_ />} />
            <Route path="/dealDetails/:id" element={<DealDetails_ />} />
            <Route path="/filter" element={<Filter_v2 />} />
            <Route path="/CookiesPolicy" element={<CookiesPolicy />} />
            <Route path="/Disclaimer" element={<Disclaimer />} />
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/TermsConditions" element={<TermsConditions />} />
            {/* <Route path="/contacts" element={<Contacts />} /> */}
            <Route path="/apitest" element={<ApiTestPage />} />

            <Route
              path="/AdminPage"
              element={
                <Container
                  sx={{
                    marginTop: '15px',
                    marginLeft: sidebarWidth,
                    width: `calc(100% - ${sidebarWidth})`,
                  }}
                >
                  <ProfileSidebar sidebarIndex={3} />
                  <AdminPage />
                </Container>
              }
            />

            <Route
              path="/MySharedDealList"
              element={
                <Container
                  sx={{
                    marginTop: '15px',
                    marginLeft: sidebarWidth,
                    width: `calc(100% - ${sidebarWidth})`,
                  }}
                >
                  <ProfileSidebar sidebarIndex={2} />
                  <MySharedDealListPage />
                </Container>
              }
            />
            <Route
              path="/DealEditor"
              element={
                <Container
                  sx={{
                    marginTop: '15px',
                    marginLeft: sidebarWidth,
                    width: `calc(100% - ${sidebarWidth})`,
                  }}
                >
                  <ProfileSidebar sidebarIndex={2} />
                  <DealEditorIndex />
                </Container>
              }
            />
            <Route path="/deals" element={<Deals />} />
            <Route path="/bigDealDay" element={<BigDeals />} />
            <Route path="/lightning" element={<DealsLightning />} />
            <Route path="/filter" element={<Filter_v2 show={true} />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/backup" element={<Backup />} />
            <Route
              path="/testPublicProfile/:id/:username"
              element={<PublicProfile />}
            />
          </Route>
        </Route>
      ) : (
        <>
          <Route path="/" element={<Backup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/set-username" element={<SetUsername />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="*" element={<Backup />} />
        </>
      )}
    </Routes>
  );
}

function App__() {
  return (
    <>
      <AppContextProvider>
        {/* <ThemeProvider theme={theme === 'light' ? light : dark}> */}
        <QueryClientProvider client={queryClient}>
          {/* <ReactQueryDevtools initialIsOpen={true} /> */}
          <AppRoutes />
        </QueryClientProvider>
        {/* </ThemeProvider> */}
        <NotificationListener />
      </AppContextProvider>
    </>
  );
}

export default App__;
