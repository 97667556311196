import { API } from 'aws-amplify';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import {
    listStores
} from '../../graphql/queries';
import { user_white_list } from "../constants/WhiteListUsers";

import {
    useTheme,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useAppContext_ } from "../../AppContextProvider";
import { storeIcon } from '../constants/StoreConstants';
import Section from '../home/Section';

function isEmpty(obj) {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }

    return true;
}

function BrandsList() {
    const navigate = useNavigate();
    const theme = useTheme();
    const [available_stores, setStores]= useState([]);
    const [pageLoading, setPageLoading]= useState(true);
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#212529' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(0),
        textAlign: 'center',
        color: theme.palette.mode === 'dark'? '#398fed' : '#3054f5',
        fontWeight: 500,
    }));
    const { user, HomeCategoryValue,setHomeCategoryValue, } = useAppContext_();

    const charList = ['0','A','B', 'C', 'D', 'E', 'F', 'G', 'H', 'I','J', 'K', 'L','M', 'N',
        'O', 'P', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Z'
    ]

    const brandIcons = [
        {
            "7-Eleven":"/brand_icons/7-Eleven.png"
        },
        Object.fromEntries(Object.entries(storeIcon).filter(([key, val]) => key.startsWith('A') && val != null)),
        Object.fromEntries(Object.entries(storeIcon).filter(([key, val]) => key.startsWith('B') && val != null)),
        Object.fromEntries(Object.entries(storeIcon).filter(([key, val]) => key.startsWith('C') && val != null)),
        Object.fromEntries(Object.entries(storeIcon).filter(([key, val]) => key.startsWith('D') && val != null)),
        Object.fromEntries(Object.entries(storeIcon).filter(([key, val]) => key.startsWith('E') && val != null)),
        Object.fromEntries(Object.entries(storeIcon).filter(([key, val]) => key.startsWith('F') && val != null)),
        Object.fromEntries(Object.entries(storeIcon).filter(([key, val]) => key.startsWith('G') && val != null)),
        Object.fromEntries(Object.entries(storeIcon).filter(([key, val]) => key.startsWith('H') && val != null)),
        Object.fromEntries(Object.entries(storeIcon).filter(([key, val]) => key.startsWith('I') && val != null)),
        Object.fromEntries(Object.entries(storeIcon).filter(([key, val]) => key.startsWith('J') && val != null)),
        Object.fromEntries(Object.entries(storeIcon).filter(([key, val]) => key.startsWith('K') && val != null)),
        Object.fromEntries(Object.entries(storeIcon).filter(([key, val]) => key.startsWith('L') && val != null)),
        Object.fromEntries(Object.entries(storeIcon).filter(([key, val]) => key.startsWith('M') && val != null)),
        Object.fromEntries(Object.entries(storeIcon).filter(([key, val]) => key.startsWith('N') && val != null)),
        Object.fromEntries(Object.entries(storeIcon).filter(([key, val]) => key.startsWith('O') && val != null)),
        Object.fromEntries(Object.entries(storeIcon).filter(([key, val]) => key.startsWith('P') && val != null)),
        Object.fromEntries(Object.entries(storeIcon).filter(([key, val]) => key.startsWith('R') && val != null)),
        Object.fromEntries(Object.entries(storeIcon).filter(([key, val]) => key.startsWith('S') && val != null)),
        Object.fromEntries(Object.entries(storeIcon).filter(([key, val]) => key.startsWith('T') && val != null)),
        Object.fromEntries(Object.entries(storeIcon).filter(([key, val]) => key.startsWith('U') && val != null)),
        Object.fromEntries(Object.entries(storeIcon).filter(([key, val]) => key.startsWith('V') && val != null)),
        Object.fromEntries(Object.entries(storeIcon).filter(([key, val]) => key.startsWith('W') && val != null)),
        Object.fromEntries(Object.entries(storeIcon).filter(([key, val]) => key.startsWith('X') && val != null)),
        Object.fromEntries(Object.entries(storeIcon).filter(([key, val]) => key.startsWith('Z') && val != null)),
    ];

    const handleTopNavChange = (e) => {
        // console.log(e.target.innerHTML);
        let letterElement;
        if(e.target.innerHTML == '0'){
            letterElement = document.querySelector(`#letter-0`);
            //console.log(`#letter-0`, letterElement)
        }
        else{
            letterElement = document.querySelector(`#letter-${e.target.innerHTML}`);
            // console.log(`#letter-${e.target.innerHTML}`, letterElement)
        }

        window.scrollTo(0, letterElement.getBoundingClientRect().top);
    };

    const handleIconClick = (event, newValue) => {
        // console.log(newValue);
        navigate({
            pathname: '/deals',
            search: createSearchParams({
              dealer_types: [newValue],
            }).toString(),
        });
        // console.log(newValue);
        //navigate("/categoryForum", { state: { name: newValue } });
      };


    useEffect(() => {
        const loadData = async() => {
            window.scrollTo(0, 0);
            //await setIsLoading(true);
            setHomeCategoryValue();
            //getDeals();
            //await setIsLoading(false);
            const fetched_stores = await API.graphql({
                query: listStores,
                variables: {
                  filter: {
                    _deleted: { ne: true },
                    count: { ge: 1 },
                  },
                }
            });
            let storeNames =  new Map();
            // console.log(fetched_stores);
            fetched_stores.data.listStores.items.map(function(store, i){
                // store.storeName
                storeNames.set(store.storeName);
            })
            let avai_store_icons = Object.fromEntries(Object.entries(storeIcon).filter(([key, val]) => storeNames.has(key) && val != null));
            let indexed_store_icons = {};
            charList.map((char) => {
                let char_exist;
                if(char == '0'){
                    char_exist = Object.fromEntries(Object.entries(avai_store_icons).filter(([key, val]) => key.startsWith('7') && val != null));
                }
                else{
                    char_exist = Object.fromEntries(Object.entries(avai_store_icons).filter(([key, val]) => key.startsWith(char) && val != null));
                }
                if(isEmpty(char_exist) == false){
                    indexed_store_icons[char] = char_exist;
                }
            })
            // console.log(indexed_store_icons);
            setStores(indexed_store_icons);
            setPageLoading(false);
        }
        if(user){
            if(user.attributes.sub in user_white_list){
                loadData();
            }
            else{
                navigate('/backup');
            }
        }
        else{
            navigate('/backup');
        }
    }, []);


    return (
        <div>
            {pageLoading == true ?
                <>
                <Section
                        title={"Loading Brands"}
                        content={
                            <div className="tw-flex tw-flex-col tw-gap-y-2 lg:tw-gap-y-4 tw-w-full">
                                <div className="tw-flex tw-p-2">
                                    <Stack
                                        direction="row"
                                        divider={<Divider orientation="vertical" flexItem color= {theme.palette.mode === 'dark'? '#1591EA' : '#0000FF'} sx={{ borderRightWidth: 2}} />}
                                        spacing={1}
                                        className = "tw-flex tw-text-string-primary tw-text-nowrap tw-overflow-auto"
                                    >
                                        {charList.map((char) => (
                                            <div className="tw-whitespace-nowrap">
                                                <Item elevation={0} onClick={handleTopNavChange} className='img-hover'>{char}</Item>
                                            </div>
                                            )
                                        )}
                                    </Stack>
                                </div>
                                {brandIcons.map((icon_object,index) => (
                                    <div id = {'letter-' + charList[index]} className="tw-flex tw-gap-1 tw-justify-start tw-items-center tw-flex-wrap tw-rounded-xl tw-p-2 md:tw-p-2 md:tw-gap-x-6 md:tw-gap-y-1 tw-no-change-color">
                                        <p className='tw-items-center tw-font-bold tw-text-string-base'>{charList[index]}</p>
                                    </div>
                                ))}
                            </div>
                        }
                    />
                </>
                :
                <div>
                    <Section
                        title={"Browse By Brands"}
                        content={
                            <div className="tw-flex tw-flex-col tw-gap-y-2 lg:tw-gap-y-4 tw-w-full">
                                <div className="tw-flex tw-p-2">
                                    <Stack
                                        direction="row"
                                        divider={<Divider orientation="vertical" flexItem color= {theme.palette.mode === 'dark'? '#1591EA' : '#0000FF'} sx={{ borderRightWidth: 2}} />}
                                        spacing={1}
                                        className = "tw-flex tw-text-string-primary tw-text-nowrap tw-overflow-auto"
                                    >
                                        {Object.entries(available_stores).map(([key,value]) => (
                                            <div className="tw-whitespace-nowrap">
                                                <Item elevation={0} onClick={handleTopNavChange} className='img-hover'>{key}</Item>
                                            </div>
                                            )
                                        )}
                                    </Stack>
                                </div>
                                {Object.entries(available_stores).map(([key,icon_object]) => (
                                    <div id = {'letter-' + key} className="tw-flex tw-gap-1 tw-justify-start tw-items-center tw-flex-wrap tw-rounded-xl tw-p-0 md:tw-gap-x-6 md:tw-gap-y-1 tw-no-change-color">
                                        <p className='tw-items-center tw-font-bold tw-text-string-base'>{key}</p>
                                        <Tabs value = {false} variant="scrollable" scrollButtons = "auto" onChange = {handleIconClick}>
                                            {Object.keys(icon_object).map((key2) => (
                                                <Tab
                                                    icon={<img
                                                        key={key2}
                                                        src={icon_object[key2]}
                                                        className="tw-h-29-icon tw-w-29-icon md:tw-w-29-icon md:tw-h-29-icon img-hover"
                                                    />}
                                                    value = {key2}
                                                    label= {<p className='tw-text-xs max-brand-label-width-lg min-brand-label-height tw-flex-wrap tw-text-center'>{key2}</p>}
                                                    sx={{ textTransform: 'none'}}
                                                    className='max-brand-section-h-46'
                                                />
                                            ))}
                                        </Tabs>
                                    </div>
                                ))}
                            </div>
                        }
                    />
                </div>
            }
        </div>
    );
  }
export default BrandsList;