import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import { Button } from '@mui/material';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

function Backup({}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const socials = [
    {
      logo: '/Frame_instagram.png',
      links: [
        {
          text: '@LordOfSavings',
          href: 'https://www.instagram.com/lordofsavings/',
        },
      ],
    },
    {
      logo: '/Frame_x.png',
      links: [
        {
          text: '@LordOfSavings',
          href: 'https://twitter.com/LordOfSavings?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor',
        },
        {
          text: '@LordOfRestocks',
          href: 'https://twitter.com/LordOfRestocks?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor',
        },
        {
          text: '@LordOfDiscounts',
          href: 'https://twitter.com/LordOfDiscounts?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor',
        },
      ],
    },
    {
      logo: '/Frame_discord.png',
      links: [
        {
          text: 'Join our Server',
          href: 'https://discord.com/invite/lordsavings',
        },
      ],
    },
  ];

  return (
    <div
      className="tw-max-h-screen tw-overflow-clip"
      style={{ background: 'url("/grid.png")', backgroundSize: '100% 100%' }}
    >
      <div className="tw-flex tw-justify-between tw-p-6">
        <Link to="/">
          <img src="/logo.png" className="tw-h-10 tw-w-10 tw-object-contain" />
        </Link>
        <Link
          to="/login"
          className="tw-no-underline tw-text-string-neutral tw-font-semibold"
        >
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            startIcon={<PermIdentityOutlinedIcon />}
            sx={{ textTransform: 'none' }}
            className="!tw-font-semibold !tw-text-base"
          >
            Login
          </Button>
        </Link>
      </div>
      <div className="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-justify-between">
        <div className="tw-contacts tw-flex tw-flex-col tw-gap-6 md:tw-w-[90%] tw-px-8 tw-pt-32 md:tw-pt-0 md:tw-pl-20 lg:tw-pl-44 tw-text-center md:tw-text-left">
          <h1 className="tw-text-title-backup tw-font-bold tw-text-4xl md:tw-text-5xl tw-m-0">
            Under Construction
          </h1>
          <p className="tw-text-lg tw-m-0 tw-text-center md:tw-text-left">
            Lord of Savings{' '}
            <span className="tw-text-text-backup">
              is currently under construction to bring you exciting updates!
              Stay tuned and keep an eye on our social media
            </span>
          </p>

          <div className="tw-self-center md:tw-self-start">
            {socials.map((entry, index) => (
              <div
                key={index}
                className="tw-flex tw-py-2 tw-gap-4 tw-items-center tw-justify-start"
              >
                <img
                  className="tw-p-0 tw-bg-gray-100 tw-rounded-lg tw-object-scale-down tw-h-[50px] md:tw-h-[60px]"
                  src={entry.logo}
                ></img>
                <div className="tw-flex tw-flex-col">
                  {entry.links.map((entry_, index) => (
                    <p key={index} className="tw-text-detail-backup tw-m-0">
                      <a
                        className="tw-link-no-underline tw-text-text-backup"
                        href={entry_.href}
                        rel="external nofollow noopener"
                        target="_blank"
                      >
                        {entry_.text}
                      </a>
                    </p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <img
          className="tw-h-[600px] md:tw-h-screen tw-object-scale-down"
          src="/hand.png"
        />
      </div>
    </div>
  );
}

export default Backup;
